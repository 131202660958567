<template lang="pug">
  .container
    KeyVisual
    CompanyProfile
    TheInformation
    AccessMap
</template>

<script>
// @ is an alias to /src
import KeyVisual from '@/components/KeyVisual.vue'
import CompanyProfile from '@/components/CompanyProfile.vue'
import TheInformation from '@/components/TheInformation.vue'
import AccessMap from '@/components/AccessMap.vue'
export default {
  name: 'home',
  components: {
    KeyVisual,
    CompanyProfile,
    TheInformation,
    AccessMap
  }
}
</script>
