export default {
    data: function(){
      return {
        CompanyLogo: require('@/assets/logo.png'),
        MapIcon: require('@/assets/map-icon.png'),
        InfoIcon: require('@/assets/info-icon.png'),
        CompanyIcon: require('@/assets/company-icon.png'),
        TelIcon: require('@/assets/tel-icon.png')
      }
    }
  }