<template lang="pug">
  #app
    TheHeader
    router-view
    TheFooter
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

html {
    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, Roboto, "游ゴシック体", YuGothic, "Yu Gothic Medium", sans-serif;
    font-feature-settings: "pkna" 1;
}

body {
    @extend html;
}

h1, h2, h3, h4, h5, h6, strong {
    @extend html;
    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, Roboto, "游ゴシック体", YuGothic, "Yu Gothic", sans-serif;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
figure {
  margin: 0;
  padding: 0;
}
img{
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}
ul {
  list-style: none;
}
.pc {
  display: inline-block;
}
.sp {
  display: none;
}
@include sp {
  .pc {
    display: none;
  }
  .sp {
   display: block;
  }
}
</style>

<script>
import TheHeader from './components/TheHeader'
import TheFooter from './components/TheFooter'
import 'ress'
export default {
  name: 'app',
  components : {
    TheHeader,
    TheFooter
  }
}
</script>